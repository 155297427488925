import React, { useEffect, useState } from 'react';
import axios from 'axios';

const SPREADSHEET_ID = '1LqPX3ES1Ymw4fWWBF6zzxtAD7SwEMDkauGbzBU4WjGo';
const RANGE = 'Transfer!A:AE';
const API_KEY = process.env.REACT_APP_GOOGLE_SHEETS_API_TOKEN;

const fetchGoogleSheetData = async () => {
  const url = `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${RANGE}?key=${API_KEY}`;
  
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch data from Google Sheets: ${response.statusText}`);
  }
  const data = await response.json();
  return data.values;
};

const GoogleSheetDataComponent = () => {
  const [googleData, setGoogleData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [dayType, setDayType] = useState(''); // State for the text input
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false); // State for the checkbox
  const currentWalletAddress = localStorage.getItem('walletAddress');

  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`) 
    ? `https://${stagingKey}.peaxel.me` 
    : 'https://peaxel.me';

  // Function to send data to the WordPress API
  const sendDataToAPI = async (data: any) => {
    try {
      const response = await axios.post(`${linkUrl}/wp-json/custom/v1/update-instagram-performance/`, {
        data_talent: data, 
        day_type: dayType // Include day_type in the API request
      });
      console.log(response.data);
    } catch (error) {
      console.error('Error sending data to API:', error);
    }
  };

  // Function to fetch data and send to API when button is clicked
  const handleFetchAndSend = () => {
    setIsFetching(true);
    fetchGoogleSheetData()
      .then((sheetData) => {
        console.log('Fetched data from Google Sheets:', sheetData);
        setGoogleData(sheetData);
        sendDataToAPI(sheetData);
      })
      .catch((err) => {
        console.error('Error fetching Google Sheets data:', err);
      })
      .finally(() => setIsFetching(false));
  };

  // Check if button should be enabled
  const isButtonDisabled = !((dayType === 'dimanche' || dayType === 'semaine') && isCheckboxChecked);

  return (
    <>
      {currentWalletAddress === '0xbdc8186faa4c50061948edfdfc4a5b534c42a45d' && (
        <>
          <input
            type="text"
            value={dayType}
            onChange={(e) => setDayType(e.target.value)}
            placeholder="Enter type"
          />
          <label>
            <input
              type="checkbox"
              checked={isCheckboxChecked}
              onChange={(e) => setIsCheckboxChecked(e.target.checked)}
            />
            Previous followers number copied?
          </label>
          <button onClick={handleFetchAndSend} disabled={isFetching || isButtonDisabled}>
            {isFetching ? 'Fetching...' : 'Scrapping Instagram Data'}
          </button>
        </>
      )}
    </>
  );
};

export default GoogleSheetDataComponent;
